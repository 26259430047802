.App {
  text-align: center;
}

input {
  border-radius: 0 !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  padding: 0 !important;
  outline: none !important;
  background: transparent;
}

.form-input {
  border-radius: 10px !important;
  padding: 0 10px !important;
  background-color: #eaeafb !important;
}

.form-input:focus{
  outline:none !important;
  outline-width:0px;
}

.form-input:disabled {
  background-color: #bebef5 !important;
}

a {
  color: #e64c4c;
}

a:hover {
  color: #e64c4c;
}

.gs-clickable-text {
  font-size: 14px;
  color: #7b7b7b;
  text-decoration-line: underline;
}

.gs-clickable-text:hover {
  cursor: pointer;
}

.btn-primary,
button {
  background-color: #e64c4c !important;
  border-color: #e64c4c !important;
  border-width: 2px !important;
  border-radius: 10px !important;
  opacity: 1 !important;
}


.btn-primary:hover,
button:hover,
.btn-secondary {
  background-color: #fff !important;
  border-color: #e64c4c !important;
  border-width: 2px;
  color: #e64c4c !important;
  opacity: 1 !important;
}

.btn-primary:disabled {
  background-color: #e64c4c !important;
  border-color: #e64c4c !important;
  opacity: 0.65 !important;
  pointer-events: none;
}

.btn-secondary:hover,
.btn-secondary:focus {
  color: #fff !important;
  background-color: #e64c4c !important;
  border-color: #e64c4c !important;
  border-width: 2px;
  border-radius: 10px;
  opacity: 1 !important;
}

.btn-secondary:disabled {
  pointer-events: none;
}

.btn-primary:disabled:hover {
  background-color: #e64c4c !important;
  border-color: #e64c4c !important;
  opacity: 0.65 !important;
}

a.btn-primary {
  background-color: #e64c4c !important;
  border: 2px solid #e64c4c;
  border-radius: 10px;
  display: block;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #fff;
  opacity: 1 !important;
}

a.btn-primary:hover {
  background-color: #fff !important;
  border-color: #e64c4c !important;
  color: #e64c4c !important;
  text-decoration: none;
  opacity: 1 !important;
}

a.btn-pink {
  background-color: #fff !important;
  border: 2px solid #e64c4c;
  border-radius: 10px;
  display: block;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #e64c4c;
  opacity: 1 !important;
}

a.btn-pink:hover {
  background-color: #e64c4c !important;
  color: #fff !important;
  text-decoration: none;
  opacity: 1 !important;
}

a.btn-secondary {
  /* background-color: #d4eeef !important; */
  border: 2px solid #299898 !important;
  border-radius: 10px;
  display: block;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #299898 !important;
  opacity: 1 !important;
}

a.btn-secondary:hover {
  background-color: #299898 !important;
  border-color: #299898 !important;
  color: #f3f7f7 !important;
  text-decoration: none;
  opacity: 1 !important;
}

.btn-blue {
  background-color: #fff !important;
  /* border: 2px solid #1D65B1; */
  border-color: #1d65b1 !important;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 10px;
  color: #1d65b1;
  opacity: 1 !important;
}

.btn-blue:hover {
  background-color: #1d65b1 !important;
  border-color: #1d65b1 !important;
  color: #fff !important;
  text-decoration: none;
  opacity: 1 !important;
}

.btn-green-secondary,
.btn-green:hover,
.btn-green:focus {
  background-color: #fff !important;
  border: 2px solid #a8ca71 !important;
  border-radius: 10px;
  color: #a8ca71 !important;
  opacity: 1 !important;
}

.btn-green,
.btn-green-secondary:hover,
.btn-green-secondary:focus {
  background-color: #a8ca71 !important;
  border-color: #a8ca71 !important;
  color: #fff !important;
  text-decoration: none;
  opacity: 1 !important;
}


.btn-lime-secondary,
.btn-lime:hover,
.btn-lime:focus {
  background-color: #eef4e3 !important;
  border: 4px solid #65a006 !important;
  border-radius: 14px;
  color: #65a006 !important;
  opacity: 1 !important;
  border: 4px solid white !important;
}

.btn-lime,
.btn-lime-secondary:hover,
.btn-lime-secondary:focus {
  background-color: #eef4e3 !important;
  border-color: #65a006 !important;
  color: #65a006 !important;
  text-decoration: none;
  opacity: 1 !important;
  border: 4px solid #65a006 !important;
  border-radius: 14px;
}

.btn-yellow-secondary,
.btn-yellow:hover,
.btn-yellow:focus {
  background-color: #fff !important;
  /* border: 2px solid #1D65B1; */
  border-color: #f6bd42 !important;
  border-radius: 10px;
  color: #f6bd42 !important;
  opacity: 1 !important;
}

.btn-yellow,
.btn-yellow-secondary:hover,
.btn-yellow-secondary:focus {
  background-color: #f6bd42 !important;
  border-color: #f6bd42 !important;
  color: #fff !important;
  text-decoration: none;
  opacity: 1 !important;
}

.btn-purple-secondary,
.btn-purple:hover,
.btn-purple:focus {
  background-color: #fff !important;
  /* border: 2px solid #1D65B1; */
  border-color: #4542c2 !important;
  border-radius: 10px;
  color: #4542c2 !important;
  opacity: 1 !important;
}

.btn-purple,
.btn-purple-secondary:hover,
.btn-purple-secondary:focus {
  background-color: #4542c2 !important;
  border-color: #4542c2 !important;
  color: #fff !important;
  text-decoration: none;
  opacity: 1 !important;
}

.btn-purple:disabled {
  background-color: #7676ee !important;
  border-color: #7676ee !important;
}

.btn-teal-secondary,
.btn-teal:hover,
.btn-teal:focus {
  background-color: #e6fff9 !important;
  border-radius: 14px;
  border: 4px solid white !important;
  color: #2e8b81 !important;
  opacity: 1 !important;
}

.btn-teal,
.btn-teal-secondary:hover,
.btn-teal-secondary:focus {
  background-color: #e6fff9 !important;
  border-color: #4ec2b3 !important;
  color: #2e8b81 !important;
  text-decoration: none;
  border: 4px solid #4ec2b3 !important;
  border-radius: 14px;
  opacity: 1 !important;
}

.btn-teal:disabled,
.btn-teal-secondary:disabled,
.btn-green:disabled,
.btn-green-secondary:disabled,
.btn-purple:disabled,
.btn-purple-secondary:disabled {
  pointer-events: none;
}

a.btn-blue {
  background-color: #fff !important;
  border: 2px solid #1d65b1;
  border-radius: 10px;
  display: block;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 10px;
  color: #1d65b1;
  opacity: 1 !important;
}

a.btn-blue:hover {
  background-color: #1d65b1 !important;
  border-color: #1d65b1 !important;
  color: #fff !important;
  text-decoration: none;
  opacity: 1 !important;
}

a.btn-green {
  background-color: #fff !important;
  border: 2px solid #299898;
  border-radius: 10px;
  display: block;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #1d65b1;
  opacity: 1 !important;
}

a.btn-green:hover {
  background-color: #299898 !important;
  border-color: #299898 !important;
  color: #fff !important;
  text-decoration: none;
  opacity: 1 !important;
}

.gs-text {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  color: #3c3c3c;
}

.gs-link {
  color: #e64c4c;
}

.gs-link:hover {
  text-decoration: underline;
}

body {
  color: #3c3c3c;
}

body.gs {
  overflow-x: hidden;
}

body.modal-open {
  overflow: hidden !important;
  padding: 0 !important;
}

.send-to-back {
  z-index: -1000;
}

.modal-backdrop {
  z-index: 10005;
}

.modal {
  z-index: 10010;
}

* {
  font-family: "Poppins", sans-serif, "FontAwesome";
}

.no-underline:hover {
  border-bottom: none !important;
}

.hover-underline:hover {
  text-decoration: underline;
}

p.gs a {
  color: #3c3c3c;
}

img.theme-icon {
  padding-top: 5rem;
}

.gs-color {
  color: #3c3c3c;
}

.gs-navbar-logo img {
  height: 6rem;
}

.gs-navbar {
  z-index: 10001;
  top: 0;
  padding: 0;
  background-color: transparent;
}

.gs-block {
  padding-top: 10rem;
  padding-bottom: 5rem;
}

.gs-logo {
  white-space: nowrap;
  height: 3.5rem;
  width: 15.75rem;
}

.gs-logo img {
  height: 100%;
}

.bg-red {
  background-color: #df7260 !important;
  color: #fff;
}

.bg-pink {
  background-color: #ffd4c4 !important;
}

.bg-yellow {
  background-color: #f1bc00 !important;
}

.bg-light-yellow {
  background-color: #fee19b !important;
}

.bg-light-blue {
  background: #f2f9fa;
}

.bg-dark-blue {
  background: #d4eeef;
}

.bg-blue {
  background-color: #69cdcd !important;
}

.bg-grey {
  background-color: #f7f8f9 !important;
}

.margin8px{
  margin:8px
}

.utilities-section{
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 20px;
  margin-left: 20px;
}

.error-output-box {
  background-color: #d4d4d4;
  border: 1px solid black;
  display:inline-block;
  padding: 8px;
  min-width: 35ch;
  margin:8px;
}

.text-light-blue {
  color: #d7ecfdff !important;
}

.text-blue {
  color: #69cdcd !important;
}

.text-dark-blue {
  color: #227ad3 !important;
}

.text-red {
  color: #e64c4c !important;
}

.text-aqua {
  color: #9ad6d6 !important;
}

.text-pink {
  color: #ec9783 !important;
}

.text-yellow {
  color: #fecb48 !important;
}

.text-black {
  color: #3c3c3c !important;
}

.gs-content {
  min-height: 100vh;
}

.gs-social-media a {
  font-size: 3rem;
  color: #3c3c3c;
  padding: 1rem;
}

.gs-social-media a:hover {
  font-size: 3rem;
  color: #e64c4c;
  padding: 1rem;
}

.gs-home-preadmit {
  max-width: 30rem;
}

.gs-form-container {
  max-width: 30rem;
  padding-top: 7rem;
}

.gs-login-image {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-height: 80%;
  padding: 0 5rem 0 5rem;
}

.gs-password-image {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-height: 80%;
  margin: 0rem 8rem 0rem -2rem;
}

.nav-pills .nav-link {
  border-radius: 0;
  color: #3d3d3d;
  padding: 1rem 0rem 1rem 2.25rem;
}

.nav-pills .nav-link {
  border-radius: 0;
  color: #3d3d3d;
  padding: 1rem 0rem 1rem 2.25rem;
}

.nav-pills .nav-link.disabled {
  border-radius: 0;
  color: #abaaaa;
  padding: 1rem 0rem 1rem 2.25rem;
}

.nav-pills .nav-link:hover {
  color: #9ad6d6;
}

.nav-pills .nav-link.active {
  background-color: #d4eeef;
  border-left: 0.75rem solid #9ad6d6;
  padding-left: 1.5rem;
  color: #3d3d3d;
  box-shadow: 0px 4px 4px rgba(212, 238, 239, 0.37);
}

.gs-htg-block {
  /* padding-top: 3rem; */
  padding-bottom: 3rem;
}

.get-connected {
  margin-bottom: 32px;
  margin-top: 24px;
}

.gs-dashboard-nav {
  position: fixed;
  height: 100vh;
  width: 18.5rem;
}

.gs-dashboard-nav-mobile {
  background-color: #f8f8f8;
}

.gs-dashboard-content {
  background-color: #f8f8f8;
  min-height: 100vh;
  margin-left: 18.5rem;
  width: calc(100% - 18.5rem);
  padding-top: 6rem;
}

.gs-home-container,
.gs-account-container {
  /* padding-top: 4rem; */
}

.gs-team-container {
  padding: 0;
}

.mb-3.d-flex.justify-content-center{
  flex-grow:0;
}

.team-container{
  margin: 0 auto;
  padding: 1rem 2rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
  align-items:flex-start;
}

.team-container > *{
  min-width:518px;
}

.member-container{
  display:flex;
  flex-wrap:wrap;
  justify-content:flex-start;
  align-items:center;
}

.gs-in-team-block {
  position: relative;
  z-index: 100;
  padding-bottom: 1.5rem;
}

.gs-no-team-block {
  position: relative;
  z-index: 100;
}

.gs-no-team-block .card {
  filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.15));
  border-radius: 4px;
  display: grid;
  grid-template-rows: auto auto auto;
  align-items: space-between;
  height: 205px;
  width: 297px;
}

.gs-no-team-block .card-body {
  display: grid;
  grid-template-columns: auto auto;
}

.gs-no-team-bg-img {
  position: fixed;
  width: calc(100% - 18.5rem);
  max-width: 1140px;
  bottom: 0px;
}

.gs-in-team-bg-img {
  position: fixed;
  width: calc((100% - 18.5rem) / 3);
  bottom: 0px;
  right: 0px;
}

.gs-theme-button{
  border-radius:14px;
  height: 48px;
  width: 150px;
  align-items:center;
  font-size:14px;
  line-height:120%;
  display:grid;
  grid-template-columns:auto auto;
}

.gs-member-card {
  background: #ffffff;
  border: 1px solid #c4d0e2;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  border-radius: 7px;
  justify-items: center;
  padding: 4px 6px;
  /* max-width: 20rem; */
}

.show-submission-button{
  min-width:300px;
  border: 2px solid #4542c2 !important;
  background-color:#4542c2 !important;
  color:  #d8d8f9 !important;
}

.show-submission-button:hover{
  min-width:300px;
  border: 2px solid #4542c2 !important;
  background-color:#fff !important;
  color: #4542c2  !important;
}

.score-container{
  display:flex;
  justify-content:flex-start;
  gap:10px;
  align-items:center;
}

.score-button{
  height:100%;
}

.panel-button-active{
  background-color:#d8d8f9 !important;
  border: 4px solid #4542c2 !important;
  color: #4542c2  !important;
}

.panel-button-disable{
  border: 4px solid white !important;
  background-color:#d8d8f9 !important;
  color: #4542c2  !important;
}

.panel-button-active:hover, .panel-button-disable:hover{
  background-color:#d8d8f9 !important;
  color: #4542c2  !important;
  border-color:#4542c2 !important;
}

.panel-button-disable:disabled{
  background-color:#d8d8f9 !important;
  color: #4542c2  !important;
}

.panel-button-active:disabled{
  background-color:#d8d8f9 !important;
  color:  #37359b !important;
  border-color:#292874 !important;
  font-weight:bold;
}

.team-item{
  margin:10px 0;
  width:100%;
}

.comment-grid{
  width:100%;
  display:grid;
  grid-template-rows: repeat(1,auto); 
  grid-template-columns:repeat(1,auto);
  grid-auto-flow:column;
  margin: bottom 10px;
}

.comment-grid > *{
  outline:1px grey solid !important;
  padding:5px;
}

.gs-member-card h5 {
  font-size: 14px !important;
  line-height: 15px !important;
  color: #170b31;
  margin: 6px;
}

.gs-member-card p {
  font-size: 10px;
  line-height: 15px;
  color: #7b7b7b;
  margin: 2px;
}

.gs-submission-block {
  display: grid;
  grid-template-columns: auto auto;
  max-width: 27rem;
}

.gs-submission-details {
  background: #f5f7fa;
  color: #3c3c3c;
  border: 1px solid #c4d0e2;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 4px;
  margin: 0px 4px;
  display: inline-flex;
  max-width: 100%;
  overflow: overlay;
}

.gs-submission-details:hover {
  color: #3c3c3c;
}

.gs-schedule-container,
.gs-workshop-container {
}

.gs-home-submission-container {
  background: linear-gradient(99.7deg, #f09594 31.31%, #ec7070 73.17%);
  border-radius: 10px;
  padding: 2rem;
}

.gs-home-slack-container {
  background: linear-gradient(90deg, #4542c2 0%, #9eece2 131.18%);
  border-radius: 10px;
  padding: 2rem 1rem 2rem 1rem;
  color: #fff;
}

.gs-home-hopin-container {
  background: linear-gradient(90deg, #ff4457 -6.96%, #f8dd80 43.12%);
  border-radius: 10px;
  padding: 2rem 1rem 2rem 1rem;
  color: #000;
}

.gs-home-mentor-session-container {
  background: linear-gradient(205.41deg, #ffc234 -3.55%, #fed26e 83.9%);
  border-radius: 10px;
  padding: 2rem;
  color: #3d3d3d;
}
.gs-home-mentor-session-container td,
.gs-home-mentor-session-container th {
  border: none !important;
  color: #3d3d3d;
}

.gs-home-slack-container .btn,
.gs-home-hopin-container .btn {
  min-width: 5rem;
  color: #227ad3;
  background-color: #fff !important;
  border-color: transparent !important;
}

.gs-home-slack-container .btn a,
.gs-home-hopin-container .btn a {
  color: #227ad3 !important;
}

.gs-home-slack-container .btn a:hover,
.gs-home-hopin-container .btn a:hover {
  color: #df7260 !important;
  text-decoration: none;
}

.gs-home-faq-container {
  border-radius: 10px;
  background-color: #fff;
  padding: 2.75rem;
}

.gs-home-faq-container .card-header,
.gs-home-faq-container .card-body {
  padding-left: 0;
  padding-right: 0;
}

.gs-home-faq-container .card-header {
  border-bottom: 2px solid rgba(0, 0, 0, 0.125);
  padding: 1rem 0 0.75rem 0;
}

.gs-home-faq-container .card-header {
  background-color: #fff;
}

.gs-home-container .card,
.gs-resources-container .card {
  border: transparent;
  border-radius: 10px;
  cursor: pointer;
}

.gs-home-container .card img,
.gs-resources-container .card img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.gs-home-container .card .btn {
  min-width: 7rem;
}

.gs-resource-theme-partners img {
  height: 4.5rem;
  padding: 0.5rem;
}

.gs-logout-button {
  position: absolute;
  bottom: 0;
  border-radius: 0;
  padding: 1rem 0rem 2.25rem 2.25rem;
}

.gs-event-block {
  /* background-color: #D4EEEF; */
  padding: 0.5rem;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent !important;
}

.fc-daygrid-dot-event {
  display: block;
}

.fc td,
.fc table {
  border-top: none !important;
  border-bottom: none !important;
}

.fc-daygrid td,
.fc-daygrid th,
.fc-daygrid table {
  border-style: none !important;
}

.fc-dayGrid3Day-view th,
.fc-dayGrid3Day-view .fc-daygrid-day {
  border-top: none !important;
  border-bottom: none !important;
  border-left: 2px solid #3c3c3c !important;
  border-right: 2px solid #3c3c3c !important;
}

.fc-dayGrid-button.fc-button-primary,
.fc-dayGrid3Day-button.fc-button-primary {
  color: #e64c4c !important;
  border-color: #ffcfce !important;
  background: #ffcfce !important;
  width: 6rem;
  border-width: 2px !important;
  border-radius: 10px !important;
}

.fc-dayGrid-button.fc-button-primary:hover,
.fc-dayGrid3Day-button.fc-button-primary:hover {
  background-color: #fff !important;
  border-color: #e64c4c !important;
  color: #e64c4c !important;
  opacity: 1 !important;
}

.fc-button-primary:not(:disabled):active,
.fc-button-primary:not(:disabled).fc-button-active {
  background-color: #e64c4c !important;
  color: #fff !important;
  border-color: #e64c4c !important;
}

.fc-today-button.fc-button.fc-button-primary,
.fc-prev-button.fc-button.fc-button-primary,
.fc-next-button.fc-button.fc-button-primary {
  background-color: transparent !important;
  border: none !important;
  color: #3d3d3d !important;
  font-size: 1.5rem;
  opacity: 1 !important;
}

.fc-today-button.fc-button.fc-button-primary:hover,
.fc-prev-button.fc-button.fc-button-primary:hover,
.fc-next-button.fc-button.fc-button-primary:hover {
  color: #e64c4c !important;
}

.fc-day-today {
  color: #e64c4c !important;
}

.nav-link {
  color: #3c3c3c !important;
}

.nav-link.active {
  color: #7676ee !important;
}

.navbar-toggler {
  background-color: #fff !important;
  border: none;
}

@media (min-width: 1450px) {
  .gs-in-team-bg-img {
    right: calc((100% - 18.5rem - 1140px) / 2);
  }
}

@media (max-width: 767.5px) {
  .gs-no-team-bg-img {
    width: 100%;
    left: 0px;
  }
  .gs-in-team-bg-img {
    width: 40%;
  }
}

@media (max-width: 360px) {
  .gs-submission-block {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
  }
}

@media (max-width:991px){
  .gs-dashboard-content {
    margin-left: auto;
    margin-right: auto;
    padding-top: 0;
    width: auto;
  }

  .col-md-6{
    width:auto !important;
  }

  .d-flex.align-items-center.row{
    gap:20px;
  }
}

.people-header {
  display: flex;
  flex-wrap:wrap;
  justify-content: space-between;
}

.gs-hacker-card {
  background-color: #fff;
  margin-bottom: 0.5rem;
  border: none;
  border-radius: 10px;
}

.gs-hacker-card .card-body {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.gs-hacker-card .card-header {
  padding: 0rem 1.5rem 0rem 1.5rem;
  border-bottom: 0px solid rgba(255, 255, 255, 0.125);
  background-color: #fff;
}

.gs-hacker-image {
  width: 40px;
  height: 40px;
}

.accordion-toggle {
  display: flex;
  align-items: center;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  flex-wrap:wrap;
}

.accordion-border {
  border-radius: 10px;
}

.hacker_name {
  font-size: 16px;
  font-weight: bold;
}
.circle_button {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #aaa8ef;
  border: solid 1px #aaa8ef;
  margin-left: auto;
  cursor:pointer;
  min-width:35px;
}

.hacker_arrow {
  width: 55%;
  margin-top: 14px;
}

.hacker_arrow_rotate {
  width: 55%;
  transform: rotate(180deg);
  margin-top: 10px;
}

.display-flex {
  display: flex;
}

.info-pair-group {
  display:flex;
  flex-direction:column;
  margin-bottom: 10px;
  justify-content: flex-start;
  align-items:flex-start;
  gap:5px;
}

.info_pair {
  flex: 1;
}

.ml-auto {
  margin-left: auto;
}

.hacker-link {
  color: #000000;
  max-width:15vw;
}

.hacker-link:hover {
  color: #4542c2;
}

.sortby-button {
  padding-right: 0.5rem;
  padding-left: 4.5rem; /* change this to make the width longer on the left side */
  position: relative;
}

.sortby-button.open .dropdown-filterby {
  position: absolute;
  top: 95%;
  right: 0;
  z-index: 1;
}

.sortby-button.open .dropdown-sortby {
  position: absolute;
  top: 95%;
  right: 0;
  z-index: 1;
}

.dropdown-button {
  background-color: #4542c2 !important;
  border-color: #4542c2 !important;
  color: white;
  border: 0px;
  border-radius: 10px;
  height: 40px;
  width: 120px;
}

.dropdown-button:hover {
  background-color: #4542c2 !important;
  border-color: #4542c2 !important;
  color: white !important;
  border: 0px;
}

.dropdown-button:focus {
  outline: none;
}

.dropdown-button-radius {
  border-radius: 10px 10px 0px 0px !important;
}

.dropdown-filterby {
  width: 192px;
  background-color: #4542c2;
  color: white;
  border-radius: 10px 0px 10px 10px;
}

.dropdown-sortby {
  width: 192px;
  height: 218px;
  background-color: #4542c2;
  color: white;
  border-radius: 10px 0px 10px 10px;
}

.judging-buttons{
  display:flex;
  align-items:center;
  justify-content:space-between;
  padding-right:5rem;
  padding-left:5rem;
}

.radio-circle {
  width: 15px;
  height: 15px;
}

.radio-text1 {
  font-size: 14px;
  margin: 13px 0px 0px 8px;
}

.radio-text2 {
  font-size: 14px;
  margin: 5px 0px 0px 8px;
}

.buttons-container {
  margin-left: auto;
  gap:2rem;
}

.purple-colour {
  color: #4542c2;
}

.font-weight-bold {
  font-weight: bold;
}

.resources-card-text {
  color: rgba(21, 0, 53, 0.45); /* 40% transparent black */
  font-size: 15px;
}

.resources-col-height {
  height: 100%;
}

.resources-lightbulb {
  margin: 1rem 0rem 1rem 1rem;
  max-width: 100%;
  height: auto;
  min-width:50px;
}

.resource-pdf-title {
  padding-top: 2rem;
}

.resources-card-body{
  display:flex;
  flex-direction: column;
  /* justify-content:space-between; */
}

.view-button {
  background-color: #4542c2 !important;
  color: #fff !important;
  text-decoration: none;
  opacity: 1 !important;
  border-radius: 10px;
  display: block;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 10px;
  font-size: 18px;
  width:100%;
  min-width:50px;
}

.view-button:hover {
  text-decoration: none;
  background-color: #5e5dbe !important;
}

.slack-img {
  height: 55px;
  width: 55px;
}

.join-slack {
  padding-right: 4rem;
}

.in-team-style {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 7rem;
}

.divider {
  padding: 8px;
}

.login-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 1.2rem;
  font-weight: 500;
}

.password-field-container {
  position: relative;
}

.password-toggle-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent !important;
  border: none !important;
  color: #7b7b7b !important;
  padding: 0 !important;
  width: auto !important;
}

.password-toggle-btn:hover {
  color: #4542c2 !important;
}

.password-toggle-btn:focus {
  box-shadow: none !important;
}

.gs-logout-button.pill{
  padding: 0.5rem 0rem !important;
  bottom:0;
  right:1rem;
}

.navbar-nav{
  position:relative;
}

.btn-red-secondary,
.btn-red:hover,
.btn-red:focus {
  background-color: #fdeded !important;
  border: 4px solid #e64c4c !important;
  border-radius: 14px;
  color: #e64c4c !important;
  opacity: 1 !important;
  border: 4px solid #e64c4c !important;
}

.btn-red,
.btn-red-secondary:hover,
.btn-red-secondary:focus {
  background-color: #fdeded !important;
  color: #e64c4c !important;
  text-decoration: none;
  opacity: 1 !important;
  border: 4px solid white !important;
  border-radius: 14px;
}

.btn-red:disabled{
  background-color: #fdeded !important;
}

.score-container > .btn-purple{
  height:64px;
}

@media only screen and (max-width: 999px) {
  .sortby-button{
    padding:0 !important;
  }

  .buttons-container{
    gap:50px;
    margin:0;
    margin-right:80px;
  }

  .display-flex.pl-5.pr-5.justify-content-between{
    padding:0 !important;
    flex-direction:column;
  }
  .hacker-name{
    width:auto;
  }

  .hacker-link{
    max-width:90vw;
  }

  .dropdown-filterby, .dropdown-sortby{
    border-radius:0 10px 10px 10px;
  }

  .gs-resources-container .card {
    padding:10px;
  }

  .people-header{
    margin-bottom:1rem !important;
  }

  .in-team-style{
    padding-top:1rem;
    padding-left:0;
  }
    
    
  .team-container > *{
    min-width:200px;
  }

  .sortby-button.open .dropdown-filterby, .sortby-button.open .dropdown-sortby{
    left:0;
    right:auto;
  }

}