/* Common Popup Styles */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popup-box {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px;
    max-height: 90vh;
    overflow-y: auto;
}

.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.popup-header h2 {
    margin: 0;
    font-size: 24px;
    color: #333;
}

.close-btn {
    background-color: white !important;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
}

/* Form Group Styles (Used in Link Popup) */
.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    color: #333;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

.form-group textarea {
    resize: vertical;
    min-height: 100px;
}

/* Popup Actions (Buttons) */
.popup-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.cancel-btn {
    background-color: #f0f0f0;
    color: white;
    border: 1px solid #ccc;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    transition: background-color 0.3s ease;
}

.cancel-btn:hover {
    background-color: white !important;
    color: #e64c4c !important;
}

.submit-btn,
.upload-btn {
    background-color: #4542c2 !important;
    color: white;
    border: 2px solid #4542c2 !important;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    transition: background-color 0.3s ease;
}

.submit-btn:hover,
.upload-btn:hover{
    background-color: white !important;
    color:#4542c2 !important;
    border: 2px solid #4542c2 !important;
}

*{
    outline:none !important;
}

.submit-btn:hover,
.upload-btn:hover {
    background-color: #0056b3;
}

/* Drop File Input Area */
.drop-file-input {
    position: relative;
    width: 100%;
    height: 150px;
    border: 2px dashed #ccc;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    margin-bottom: 20px;
}

.drop-file-input input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.drop-file-input:hover,
.drop-file-input.dragover {
    border-color: #007bff;
    background-color: #e6f0ff;
}

.drop-file-input__label {
    text-align: center;
    color: #666;
    font-weight: 600;
    padding: 10px;
}

.drop-file-input__label img {
    width: 60px;
    margin-bottom: 10px;
}

/* File Preview Section */
.drop-file-preview {
    margin-top: 20px;
}

.drop-file-preview__title {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin-bottom: 15px;
}

.drop-file-preview__item {
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.drop-file-preview__item img {
    width: 30px;
    height: 30px;
    margin-right: 15px;
}

.drop-file-preview__item__info {
    flex-grow: 1;
}

.drop-file-preview__item__info p {
    margin: 0;
    font-size: 14px;
    color: #333;
}

/* Uploaded Links List */
.uploaded-links-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.uploaded-link-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    transition: box-shadow 0.3s ease;
}

.uploaded-link-item:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.link-info a {
    font-size: 16px;
    color: #007bff;
    text-decoration: none;
}

.link-info a:hover {
    text-decoration: underline;
}

.link-note {
    font-size: 14px;
    color: #666;
    margin-top: 5px;
}

.file-action-icon {
    color: #666;
    cursor: pointer;
    margin-left: 10px;
    transition: color 0.3s ease;
}

.file-action-icon:hover {
    color: #007bff;
}