/* File Submission Form */
.file-submission-form {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.file-submission-form h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.file-submission-form h2 {
    font-size: 20px;
    color: #333;
    margin-top: 30px;
    margin-bottom: 15px;
}

.upload-file-btn, .add-link-btn {
    background-color: #4542c2 !important;
    color: white;
    border: 2px solid #4542c2 !important;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-right: 10px;
    font-weight: 600;
    transition: background-color 0.3s ease;
}

.upload-file-btn:hover, 
.upload-file-btn:focus,
.add-link-btn:focus,
.add-link-btn:hover {
    background-color: white !important;
    color:#4542c2 !important;
    border: 2px solid #4542c2 !important;
    outline:none !important;
}

/* Uploaded Files List */
.uploaded-files-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.uploaded-file-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    transition: box-shadow 0.3s ease;
}

.uploaded-file-item:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.uploaded-file-item span {
    font-size: 16px;
    color: #333;
    flex-grow: 1;
    margin-right: 15px;
}

.file-action-icon {
    color: #666;
    cursor: pointer;
    margin-left: 10px;
    transition: color 0.3s ease;
}

.file-action-icon:hover {
    color: #007bff;
}

/* No Files Uploaded Message */
.uploaded-files-list p {
    font-size: 16px;
    color: #666;
    text-align: center;
    margin-top: 20px;
}