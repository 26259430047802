.mainContainer{
    display:flex;
    flex-direction:column;
    width:100%;
    height:100%;
}

*{
    word-break: keep-all;
    text-overflow: ellipsis;
}

td{
    overflow:hidden;
}

header{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
}

.button-container{
    display:flex;
    gap:10px;
    align-items:center;
    font-size:18px;
    border-radius: 5px;
    padding:0.5rem;
    cursor:pointer;
}

.profile-button{
    border: 1.5px solid #150035;
    align-self: flex-end;
    margin-top:1rem;
}

.rubric-content{
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    padding:2rem;
    height:100%;
    gap:10px;
}

.padding-top-0{
    padding-top:0;
}

.rubricPopupContainer .modal-xl{
    max-width:2000px;
    padding-left:2rem;
    padding-right:2rem;
}

.btn-primary{
    max-width:150px;
}

.popup-content-1{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}

.rubric-grid{
    width:100%;
    display:grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: 1fr; 
    margin: bottom 10px;
    gap:2rem;
}

.rubric-column{
    border: 1.5px solid #4c2d7c;
    padding: 2em;
    border-radius:25px;
    width:100%;
    display:grid;
    grid-template-rows: 50px auto;
    gap:1em;
}

.rubric-question{
    position:relative;
    white-space: pre-line;
    padding:1rem;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
    height:clamp(100%,100%, 300px);
    gap:1rem;
    background-color: white;
    border-radius:10px;
    outline: 2px solid transparent !important;
    transition: all 0.3s ease;
}

.column-title{
    font-weight:bold;
    font-size: clamp(10px, 1em, 20px);
    height:100%;
    border-radius:20px;
    background-color: #bebef5;
    padding:1em;
    overflow-y:hidden;
    white-space:nowrap;
    text-overflow:unset;
}

.rubric-body{
    overflow:scroll;
    background-color: #f8f8f8;
}

.points-input{
    width:2em;
    padding:0.25rem !important;
    height:2em;
}

.rubric-header{
    width:100%;
    display:flex;
    justify-content:space-between;
    align-items:center;
    flex-wrap:wrap;
    gap:10px;
}

.rubric-question-title{
    font-weight:bold;
}

.rubric-input{
    background:#bebef5;
    border-radius:4px !important;
}

.rubric-comment-input{
    width: 100%;
    padding: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    outline: none;
    resize: none; /* Disable manual resizing */
    overflow-y: scroll; /* Hide scrollbars */
    height:18em ;
    overflow-x:hidden;
}

.rubric-comment{
    grid-row:span 2;
}

.rubric-info-container{
    display:flex;
    justify-content: center;
    flex-wrap:wrap;
    row-gap: 6px;
    column-gap: 50px;
    align-items:center;
}

.question-container{
    position:relative;
    display:flex;
    flex-direction:column;
    justify-content: center;
    flex-wrap:wrap;
}

.criteria-container{
    position:absolute;
    visibility:hidden;
    transition: opacity 0.2s ease, visibility 0.2s ease;
    width:64vw;
    background-color:grey;
    border-radius:10px;
    opacity:0;
    margin:10px 0;
    z-index:100;
    border: 2px solid grey;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    display:grid;
    gap:1px;
    grid-template-rows: repeat(2, auto);
    grid-auto-flow: column;
    overflow:hidden;
}

.criteria-container.top{
    bottom:100%;
}

.criteria-container.bottom{
    top:100%;
}

.criteria-container.show{
    opacity:1;
    visibility:visible;
}

.criteria-container.hide{
    opacity:0;
    visibility:hidden;
}

.criteria-container.center{
    left:50%;
    transform:translateX(-50%);
}

.criteria-container.left{
    right:0;
}

.criteria-item{
    padding:10px;
    background-color:#f8f8f8;
    outline:1px solid grey;
    font-size:14px;
    white-space: pre-line;
}

.criteria-points{
    font-weight:bold;
    background-color:#bebef5
}

.point-outline{
    outline-color:grey !important;
}

.total-label{
    font-size:20px;
}

.total-container{
    margin:10px;
    display:flex;
    align-items:center;
    justify-content:center;
    width:10rem;
    height:3rem;
    outline:1px solid grey;
    border-radius:10px;
    background-color: white;
}

.no-transition > .modal-dialog{
    transition: all 0s !important;
}

.modal-xl > .modal-content{
    height:90vh;
}

button:focus {
    outline: none;
}

.large-switch{
    transform:scale(1.5);
    padding-right: 3rem;
    padding-left:3.5rem;
}

.search-bar{
    border-radius:12px !important;
    width:clamp(250px, 30vw, 500px);
    border:1px solid lightgray!important;
}

input:focus-visible{
    outline:none !important;
}

.table-header{
    width:100%;
    display:flex;
    align-items: center;
    flex-direction: column;
    gap:15px;
}

.bold{
    font-weight:bold;
}

.welcome{
    display:flex;
    justify-content: center;
    color: #150035;
    font-size: clamp(20px, 4vw, 35px);
}

.submitted-switch{
    display:flex;
    justify-content: center;
    align-items:center;
    align-self:flex-end;
    padding:12px 0;
    gap:10px;
    font-size: clamp(10px, 1em, 30px);
}

.main-container{
    display:flex;
    flex-direction:column;
}

.profile-icon{
    aspect-ratio: 1;
}

.thing{
    margin-left:-55px;
}

.dropdown-color{
    background-color: #4542C2 !important;
    border-color:#4542C2 !important;
}

.dropdown-color:hover{
    border-color:#4542C2 !important;
    color:#4542C2 !important;
}

.react-bs-table-sizePerPage-dropdown > .dropdown-toggle {
    border-color:#4542C2 !important;
    color:#4542C2 !important;
}

.react-bs-table-sizePerPage-dropdown>.dropdown-toggle:hover{
    background-color: #4542C2 !important;
    border-color:#4542C2 !important;
}

.col-md-6.col-xs-6.col-sm-6.col-lg-6{
    display:flex;
    align-items: center;
}

.react-bootstrap-table-pagination{
    width:100%;
}

.active.page-item{
    margin-left:-85%;
}

.page-link{
    background-color: #4542C2 !important;
    border-color:#4542C2 !important;
}

.filter-container{
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    flex-wrap:wrap;
    gap:15px;
}

.x-btn {
    position:absolute;
    top:1rem;
    right:1rem;
    background-color: #f8f8f8 !important;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
}

.x-btn:hover {
    background-color: #f8f8f8 !important;
}

.sortby-button{
    padding:0 !important;
}

.judging-main-container{
    margin-top:-5rem;
}

.gs-dashboard-content:has(.judging-main-container){
    display:flex;
}

.dropdown-items{
    overflow-y:scroll;
    height:50vh;
}

.submitted-tooltip-container{
    position:absolute;
    display:none;
}


.judge-dropdown-button{
    width:140px !important;
}


@media only screen and (max-width:1025px){
    .tooltip{
        margin-bottom:0;
    }
}

@media only screen and (max-width: 768px){
    .active.page-item{
        margin-left:0;
        margin-top:1rem;
    }

    .modal-xl > .modal-content{
        width:95vw;
        max-width:95vw !important;
        height:95vh;
    }

    .rubricPopupContainer .modal-xl {
        padding:0 !important;
        justify-content:center;
    }

    .rubric-content, .rubric-column{
        padding: 1rem;
    }

    .column-title{
        overflow-x:scroll;
    }

    .criteria-container{
        overflow:scroll;
    }

    .judging-main-container{
        margin-top:0;
    }

}